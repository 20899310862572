import styled from "styled-components";
import { FlexipageColors } from "../FlexipageStyles";

export const CallToActionPanelsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 2em 0;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`
