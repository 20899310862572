import { FlexipageColors } from "../FlexipageStyles";

export function animatePage(animationTargets) {
  let remainingTargets = [];
  animationTargets.forEach(function (target, index) {
    let offsetThreshold = 70;
    let mobileOffset = target.getAttribute('data-animation-trigger-offset-mobile');
    let desktopOffset = target.getAttribute('data-animation-trigger-offset');

    if(mobileOffset && Number(mobileOffset) !== false && window.innerWidth <= FlexipageColors.breakpointTablet){
        offsetThreshold = Number(mobileOffset);
    } else if (desktopOffset && Number(desktopOffset)) {
      offsetThreshold = Number(desktopOffset);
    }

    if (isElementInViewport(target, offsetThreshold)) {
      let animationElements = Array.from(target.querySelectorAll('[data-animation]'));
      if (target.hasAttribute('data-animation')) {
        animationElements.push(target);
      }
      animationElements.forEach(function (element, index) {
        let animationClass = element.getAttribute('data-animation');
        let animationDelay = element.getAttribute('data-animation-delay');
        let animationDuration = element.getAttribute('data-animation-duration');
        // Add the animation classes
        if (animationClass) {
          let classesToAdd = ['animate__animated', ...animationClass.trim().split(/\s+/)];
          element.classList.add(...classesToAdd);
        }
        // Apply animation delay
        if (animationDelay) {
          element.style.animationDelay = animationDelay;
        }
        // Apply animation duration
        if (animationDuration) {
          element.style.animationDuration = animationDuration;
        }
      });
    } else {
      // If the target is not yet animated, add it to the remainingTargets
      remainingTargets.push(target);
    }
  });
  // Update the animationTargets with the remaining elements
  animationTargets = remainingTargets;

  return animationTargets;
}

function isElementInViewport(el, percentage) {
  let rect = el.getBoundingClientRect();
  let viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  let offsetThresholdTop = (viewportHeight * percentage) / 100;
  let offsetThresholdBottom = (viewportHeight * (100 - percentage)) / 100;

  let topInViewport = rect.top >= 0 && rect.top <= offsetThresholdTop;
  let bottomInViewport = rect.bottom >= 0 && rect.bottom <= viewportHeight && rect.bottom >= (offsetThresholdBottom);

  // Check if element is larger than viewport and user is within the element
  let elementCoversViewport = rect.top <= 0 && rect.bottom >= viewportHeight;

  // Check if element is fully within viewport
  let elementFullyInViewport = rect.top >= 0 && rect.bottom <= viewportHeight;

  return topInViewport || bottomInViewport || elementCoversViewport || elementFullyInViewport;
}