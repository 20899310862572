import styled from "styled-components";
import { contentStyles } from "../Global/FlexipageStyles";

export const AccordionRowWrapper = styled.section`
    ${contentStyles}

    .accordion-row-copy{
        margin-bottom: 2em;
    }

    details{
        margin-bottom: 1em;

        &:last-child{
            margin-bottom: 0;
        }

        summary{
            background-color: var(--color--accordion--summary--background);
            color: var(--color--accordion--summary--text);
            padding: 25px 55px 25px 25px;
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;

            &::marker, &::-webkit-details-marker{
                display: none;
            }

            &::before{
                content: '';
                position: absolute;
                right: 1.25em;
                display: block;
                width: 20px;
                height: 4px;
                background-color: var(--color--accordion--toggle);
            }

            &::after{
                content: '';
                position: absolute;
                right: 1.25em;
                display: block;
                width: 4px;
                height: 20px;
                background-color: var(--color--accordion--toggle);
                margin-right: 8px;
                transition: height 0.2s;
            }
        }

        &[open]{
            summary{
                &::after{
                    height: 0;
                }
            }
        }

        .accordion-item-copy{
            background-color: var(--color--accordion--description--background);
            color: var(--color--accordion--description--text);
            padding: 25px;

            a:not('.button'){
                color: var(--color--accordion--description--link);
                text-decoration: underline;
                transition: opacity 0.2 ease;

                &:hover{
                    opacity: 0.7;
                    text-decoration: none;
                }
            }

            *:last-child{
                margin-bottom: unset;
            }
        }
    }

`