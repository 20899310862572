import React from "react";
import RichTextRenderer from "../Global/RichTextRenderer/RichTextRenderer";
import VideoEmbed from "../Global/VideoEmbed/VideoEmbed";

const AltItem = ({data}) => {

    const image = data?.image?.fluid?.src || data?.image?.file?.url;
    const video = data?.video?.videoUrl;
    const copy = data?.copy?.json;
    let altText = data?.image?.description;
    let content = false;

    if (!altText) {
        altText = data?.image?.title;
        if (!altText) {
            altText = 'Alt Row Image';
        }
    }
    if (video) {
        content = (
        <div className="alt-video-container">
            <div className="alt-video-wrapper">
                <VideoEmbed url={video} />
            </div>
        </div>);
    } else if (image) {
        content = (
            <div className="image">
                <img src={image} alt={altText} />
            </div>
        );
    }

    return(
        <div className="alt-item">
            <div className="copy-container">
                <div className="copy">
                    <RichTextRenderer RichText={copy}/>
                </div>
            </div>
            {content &&
                content
            }
        </div>
    );
}

export default AltItem;