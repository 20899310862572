import styled from "styled-components";
import { contentStyles, FlexipageColors } from "../Global/FlexipageStyles";

export const TestimonialsRowWrapper = styled.section`
    ${contentStyles}

    ul.slick-dots{
        position: relative;
        padding: 0;
        bottom: 0;
        margin-top: 2em;

        li{
            margin: 0 5px;
            
            button{
                font-size: 0;
                line-height: 0;
                margin: 0;

                &:hover{
                    background-color: transparent;
                }
                &::before{
                    background-color: var(--color--button--background);
                    content: '';
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                }
            }

            &::before{
                content: unset;
            }
        }        
    }

    .slick-slider{
        margin: 0 -15px;

        .slick-slide{
            padding: 0 15px;
            box-sizing: border-box;

            .slide{
                .slide-content{
                    display: grid;
                    grid-template-columns: 4fr 5fr;
                    gap: 60px;

                    &.no-image{
                        grid-template-columns: 1fr;
                    }

                    @media screen and (max-width: ${FlexipageColors.breakpointTablet}){
                        display: block;
                        
                        .image{
                            margin-bottom: 3em;

                            img{
                                width: 600px;
                                margin: auto;    
                            }
                        }

                        .copy-wrapper{
                            text-align: center;
                        }
                    }

                    .image{
                        overflow: hidden;

                        img{
                            max-width: 100%;
                        }
                        
                    }

                    .copy-wrapper{
                        display: flex;
                        align-items: center;
                        
                        .name{
                            margin-bottom: .25em;
                            font-size: 1.25rem;
                            font-weight: bold;
                        }

                        .name, .position{
                            color: var(--color--paragraph);
                        }

                        .copy{
                            margin-top: 1em;
                        }

                        *:last-child{
                            margin-bottom: unset;
                        }
                    }
                }
            }
        }
    }
`