import React from "react";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer"
import {BLOCKS, INLINES} from "@contentful/rich-text-types"
import VideoEmbed from "../VideoEmbed/VideoEmbed";
import ButtonLink from "../ButtonLink/ButtonLink";
import TabsInterface from "../TabsInterface/TabsInterface";
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage";
import { headingAltText } from "../utils/flexipageHelpers";
import HeroPanel from "../../HeroSection/HeroPanel";
import CallToActionPanels from "../CallToActionPanels/CallToActionPanels";

const RichTextRenderer = ({RichText}) => {

    //console.log(RichText);

    let callToActionGroup = [];

    function formatHeadings(headerContent) {
        let headingCopy = '';
        headerContent.forEach(element => {
            headingCopy += element.value;
        });
        headingCopy = headingAltText(headingCopy);
        return headingCopy;
    }

    const options = {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node) => {
                const linkText = node.content[0].value;
                const linkUrl = node.data.target.fields.file["en-US"].url;
                if (linkText && linkUrl){
                    return <a href={`https:${linkUrl}`} target="_blank">{linkText}</a>
                }
                return;
            },
            [BLOCKS.TABLE]: (node) => {
				const firstRow = node.content[0];
                const firstRowHeadingCells = firstRow.content.filter(cell => cell);
                const firstRowHasHeadings = firstRow.content.length == firstRowHeadingCells.length;
                const renderRows = (rows, isHeader = false) => {
                    return rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.content.map((cell, cellIndex) => {
                                cell.content.filter((cellContent) => cellContent.nodeType = "");
                                const callContent = cell["content"][0].content[0].value;
                                if (isHeader) {
                                    return <th key={cellIndex}>{callContent}</th>;
                                }
                                return <td key={cellIndex}>{callContent}</td>;
                            })}
                        </tr>
                    ));
                };
            
                if (firstRowHasHeadings) {
                    return (
                        <table>
                            <thead>{renderRows([firstRow], true)}</thead>
                            <tbody>{renderRows(node.content.slice(1))}</tbody>
                        </table>
                    );
                }
            
                return (
                    <table>
                        <tbody>{renderRows(node.content)}</tbody>
                    </table>
                );
			},
            [INLINES.EMBEDDED_ENTRY]: (node) => {
                const contentType = node.data.target.sys.contentType.sys.id;
                if (contentType === 'buttonLink') {
                    const data = node.data.target.fields;
                    return <ButtonLink data={data}/>
                }
            },
            [INLINES.ENTRY_HYPERLINK]: (node) => {
                const linkText = node.content[0].value
                const linkUrl = node.data.target.fields.slug["en-US"];
                if (linkText && linkUrl){
                    return <a href={`/${linkUrl}/`}>{linkText}</a>
                }
                return;
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node, children, index) => {
                const contentType = node.data.target.sys.contentType.sys.id;
                if(contentType === 'videoEmbed'){
                    const videoURL = node.data.target.fields.videoUrl["en-US"];
                    if (videoURL) {
                        return <div className="gc-video-container"><VideoEmbed url={videoURL} /></div>;
                    }
                }
                if(contentType === 'tabbedContentContainer'){
                    const tabsData = node.data.target.fields;
                    if (tabsData) {
                        return <TabsInterface data={tabsData} />;
                    }
                }
                if(contentType === 'flexipageResponsiveImageEmbed'){
                    const ResponsiveImageData = node.data.target.fields;
                    if (ResponsiveImageData) {
                        return <ResponsiveImage data={ResponsiveImageData}/>
                    }
                }
                if (contentType === 'callToActionBox') {
                    const ctaBoxData = node.data.target.fields;
                    const ctaBoxImage = ctaBoxData.icon["en-US"].fields;
                    const ctaBoxLabel = ctaBoxData.label["en-US"];
                    const ctaBoxLink = ctaBoxData.link["en-US"];
                    callToActionGroup.push(
                        <HeroPanel label={ctaBoxLabel} link={ctaBoxLink} image={ctaBoxImage}/>
                    );

                    const nodeIndex = RichText.content.indexOf(node);
                    const nextNode = RichText.content[nodeIndex + 1];
                    const nextIsCallToActionBox = nextNode && nextNode.nodeType === BLOCKS.EMBEDDED_ENTRY && nextNode.data.target.sys.contentType.sys.id === 'callToActionBox';
                    if (!nextIsCallToActionBox) {
                        const groupToRender = (
                            <CallToActionPanels key={`group-${nodeIndex}`} panels={callToActionGroup} />
                        );
                        callToActionGroup = []; // Reset the group
                        return groupToRender;
                    }
                    return null; // Skip rendering until the group is complete
                }
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                return (
                  <img
                    src={`https:${node.data.target.fields.file["en-US"].url}`}
                    height={node.data.target.fields.file["en-US"].details.image.height}
                    width={node.data.target.fields.file["en-US"].details.image.width}
                  />
                )
            },
            [BLOCKS.HEADING_1]: (node) => {
                const headingCopy = formatHeadings(node.content);
                return (
                    <h1 dangerouslySetInnerHTML={{ __html: headingCopy }}></h1>
                )
            },
            [BLOCKS.HEADING_2]: (node) => {
                const headingCopy = formatHeadings(node.content);
                return (
                    <h2 dangerouslySetInnerHTML={{ __html: headingCopy }}></h2>
                )
            },
            [BLOCKS.HEADING_3]: (node) => {
                const headingCopy = formatHeadings(node.content);
                return (
                    <h3 dangerouslySetInnerHTML={{ __html: headingCopy }}></h3>
                )
            },
            [BLOCKS.HEADING_4]: (node) => {
                const headingCopy = formatHeadings(node.content);
                return (
                    <h4 dangerouslySetInnerHTML={{ __html: headingCopy }}></h4>
                )
            },
            [BLOCKS.HEADING_5]: (node) => {
                const headingCopy = formatHeadings(node.content);
                return (
                    <h5 dangerouslySetInnerHTML={{ __html: headingCopy }}></h5>
                )
            },
            [BLOCKS.HEADING_6]: (node) => {
                const headingCopy = formatHeadings(node.content);
                return (
                    <h6 dangerouslySetInnerHTML={{ __html: headingCopy }}></h6>
                )
            },
        },
    }

    const copy = documentToReactComponents(RichText, options);

    return(
        <>
            {copy}
        </>
    )
}

export default RichTextRenderer;