import React from "react";
import { PanelsRowWrapper } from "./PanelsRow.styles";
import Panel from "./Panel";
import RichTextRenderer from "../Global/RichTextRenderer/RichTextRenderer";

const PanelsRow = ({data}) => {

    const copy = data.copy?.json;
    const rowColumns = data.columnCount;
    const rowOption = data.rowStyle;
    const panelsArray = data.panelItems;
    const panelsMarkup = [];
    const prevWidgetClass = data.addClassToPrevious ? data.addClassToPrevious  : '';

    const panelRowStyles = ['bg-white no-panels', 'bg-white row-panels panel-gradient', 'bg-gradient-neutral row-panels panel-white'];
    let rowStyle = '';
    let panelClasses = '';
    let rowLayout;

    switch (rowColumns) {
        case "Two Columns":
            rowLayout = 'two-column';
            break;
        case "Three Columns":
            rowLayout = 'three-column';
            break;
        default:
            rowLayout = 'two-column';
            break;
    }

    switch (rowOption) {
        case "Grid":
            rowStyle = panelRowStyles[0];
            break;
        case "Row White Gradient Panels":
            rowStyle = panelRowStyles[1];
            panelClasses = 'bg-gradient-neutral';
            break;
        case "Row Gradient White Panels":
            rowStyle = panelRowStyles[2];
            break;
        default:
            rowStyle = panelRowStyles[0];
            break;
    }

    if (panelsArray) {
        panelsArray.forEach(panelItem => {
            panelsMarkup.push(<Panel data={panelItem} classes={panelClasses}/>)
        });    
    }

    return(
        <PanelsRowWrapper id={data.rowId} className={`flexipage-row ${rowStyle} ${rowLayout}${prevWidgetClass}`}>
            <div className="flexipage-container text-center">
                {copy &&
                    <div className="panel-row-intro" data-animation-trigger="true" data-animation="animate__fadeIn" data-animation-duration="1s" data-animation-delay="0.1s">
                        <RichTextRenderer RichText={copy}/>
                    </div>    
                }
                <div className="panels-container" data-animation-trigger="true" data-animation="animate__fadeIn" data-animation-duration="1s" data-animation-delay="0.1s">
                    {panelsMarkup}
                </div>
            </div>
        </PanelsRowWrapper>
    )
}

export default PanelsRow;