import styled from "styled-components"

export const StyledMapSection = styled.div`
  @media (min-width: 992px) {
    height: 700px;
  }
  .__react_component_tooltip.type-dark.place-top{
    color: #fff;
    background: #0C6AB3;
    border: 1px solid transparent;
    &::after{
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      bottom: -6px;
      left: 50%;
      margin-left: -8px;
      border-top-color: #0C6AB3;
      border-top-style: solid;
      border-top-width: 6px;
    }
  }
`
