import styled from "styled-components";
import { contentStyles } from "../Global/FlexipageStyles";

export const CallToActionRowWrapper = styled.section`
    ${contentStyles}

    &.flexipage-row{
        padding: 3em 20px!important;
    }

    .flexipage-container, .cta-content{
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
    }

    h2{
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;
        margin: 0;
    }

    .cta-content {
        flex-grow: 1;

        .cta-tagline {
            text-decoration: none;
            font-size: 18px;
            line-height: 27px;
            color: #144056;
            position: relative;
            margin-right: auto;
        }

        a.button{
            margin-right: 0;
        }
    }
`