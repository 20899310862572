import React from 'react';
import { VideoEmbedWrapper } from './VideoEmbed.styles';

const VideoEmbed = ({ url }) => {
  const getVimeoEmbedUrl = (vimeoUrl) => {
    const vimeoId = vimeoUrl.split('/').pop(); // Extract the video ID from the URL
    return `https://player.vimeo.com/video/${vimeoId}`;
  };

  const getHubspotEmbedUrl = (hubspotUrl) => {
    const url = new URL(hubspotUrl);
    url.searchParams.set('autoplay', '1');
    url.searchParams.set('mute', '1');
    return url.toString();
  };

  const renderVideo = () => {
    if (url.endsWith('.mp4') || url.endsWith('.webm') || url.endsWith('.ogg')) {
      const videoElement = `<video autoplay loop muted playsinline controls>
          <source src="${url}" type="video/mp4" />
          Your browser does not support the video tag.
        </video>`;
      return (
        <div className="description-content" dangerouslySetInnerHTML={{__html: videoElement}}></div>
      );
    } else if (url.includes('vimeo.com')) {
      const embedUrl = getVimeoEmbedUrl(url);
      return (
        <iframe
          src={embedUrl}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Vimeo Video"
        ></iframe>
      );
    } else if (url.includes('hubspot')) {
      const embedUrl = getHubspotEmbedUrl(url);
      return (
        <iframe
          src={embedUrl}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="HubSpot Video"
        ></iframe>
      );
    } else {
      return <p>Invalid video URL. Please provide a valid Vimeo or HubSpot URL.</p>;
    }
  };

  return <VideoEmbedWrapper>{renderVideo()}</VideoEmbedWrapper>;
};

export default VideoEmbed;
