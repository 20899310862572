import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import {BnplLogosWrapper} from "./BnplLogos.styles"
import { useEffect } from "react"
import LogoImages from "./LogoImages"
import LogoImages2 from "./LogoImages2"
import LogoImages3 from "./LogoImages3"
import LogoImages4 from "./LogoImages4"
import LogoImages5 from "./LogoImages5"

const BnplLogos = () => {

    useEffect(() => {
            //desktop sliders
            const LogosSlider = document.getElementById('LogosSlider');
            const LogosSlider2 = document.getElementById('LogosSlider2');
            //mobile sliders
            const LogosSlider3 = document.getElementById('LogosSlider3');
            const LogosSlider4 = document.getElementById('LogosSlider4');
            const LogosSlider5 = document.getElementById('LogosSlider5');

            const interval = setInterval(() => {
            if (window.innerWidth > 767) {
                const SliderWidth = LogosSlider.scrollWidth;
                const slider1FirstItem = document.querySelector('#LogosSlider p');
                const slider2FirstItem = document.querySelector('#LogosSlider2 p');

                if(!isElementInViewport(slider1FirstItem)){
                    LogosSlider.appendChild(slider1FirstItem);
                    LogosSlider.scrollLeft = (LogosSlider.scrollLeft - slider1FirstItem.offsetWidth);
                    LogosSlider2.appendChild(slider2FirstItem);
                    LogosSlider2.scrollLeft = (LogosSlider2.scrollLeft - slider2FirstItem.offsetWidth);
                    }
                    if (LogosSlider.scrollLeft !== SliderWidth) {
                    LogosSlider.scrollLeft = (LogosSlider.scrollLeft + 1);
                    LogosSlider2.scrollLeft = (LogosSlider2.scrollLeft + 1);
                    }
                }
                else if (window.innerWidth < 768){

                    const SliderWidthMobile = LogosSlider3.scrollWidth;
                    const slider3FirstItem = document.querySelector('#LogosSlider3 p');
                    const slider4FirstItem = document.querySelector('#LogosSlider4 p');
                    const slider5FirstItem = document.querySelector('#LogosSlider5 p');

                    if(!isElementInViewport(slider3FirstItem)){
                        LogosSlider3.appendChild(slider3FirstItem);
                        LogosSlider3.scrollLeft = (LogosSlider3.scrollLeft - slider3FirstItem.offsetWidth);
                        LogosSlider4.appendChild(slider4FirstItem);
                        LogosSlider4.scrollLeft = (LogosSlider4.scrollLeft - slider4FirstItem.offsetWidth);
                        LogosSlider5.appendChild(slider5FirstItem);
                        LogosSlider5.scrollLeft = (LogosSlider5.scrollLeft - slider5FirstItem.offsetWidth);
                        }
                        if (LogosSlider3.scrollLeft !== SliderWidthMobile) {
                        LogosSlider3.scrollLeft = (LogosSlider3.scrollLeft + 1);
                        LogosSlider4.scrollLeft = (LogosSlider4.scrollLeft + 1);
                        LogosSlider5.scrollLeft = (LogosSlider5.scrollLeft + 1);
                        }
                }
            }, 15);

            function isElementInViewport (el) {
                var rect = el.getBoundingClientRect();
                return rect.right > 0;
            }

            return () => clearInterval(interval);
    }, [])

    return(
        <BnplLogosWrapper className="py-5">

            <div className="StyledLogosSlider slider-1 py-1 d-none d-md-block">
                <LogoImages />
            </div>
            <div className="StyledLogosSlider slider-2 py-1 d-none d-md-block">
                <LogoImages2 />
            </div>

            <div className="logos-container-mobile">
                <div className="StyledLogosSlider py-1 d-block d-md-none">
                    <LogoImages3 />
                </div>
                <div className="StyledLogosSlider py-1 d-block d-md-none">
                    <LogoImages4 />
                </div>
                <div className="StyledLogosSlider py-1 d-block d-md-none">
                    <LogoImages5 />
                </div>
            </div>
        </BnplLogosWrapper>
    )
}

export default BnplLogos