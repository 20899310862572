import * as React from "react"

function SVGArrow() {
  return (
    <svg width="29" height="17" viewBox="0 0 29 17" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1878 7.70487L21.1874 0.704514C20.7579 0.274947 20.0614 0.274933 19.6318 0.704515C19.2022 1.13409 19.2022 1.83058 19.6318 2.26015L24.7544 7.38271L1.55585 7.38274C0.948288 7.38273 0.455787 7.87523 0.455872 8.48272C0.455801 9.09019 0.948301 9.58269 1.55585 9.58269L24.7543 9.58269L19.6318 14.7053C19.2022 15.1348 19.2022 15.8313 19.6318 16.2609C20.0614 16.6905 20.7578 16.6905 21.1874 16.2609L28.1878 9.26051C28.6174 8.83092 28.6174 8.13451 28.1878 7.70487Z" fill="url(#paint0_linear_142_5)"/>
      <defs>
      <linearGradient id="paint0_linear_142_5" x1="-9.26861" y1="13.7278" x2="15.4801" y2="-11.021" gradientUnits="userSpaceOnUse">
      <stop stop-color="#41A4A0"/>
      <stop offset="1" stop-color="#2179B9"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default SVGArrow
