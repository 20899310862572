import React from "react";
import { CallToActionPanelsWrapper } from "./CallToActionPanels.styles";

const CallToActionPanels = ({panels}) => {

    return(
        <CallToActionPanelsWrapper className="callToActionGroupzz">
            {panels}
        </CallToActionPanelsWrapper>
    )
}

export default CallToActionPanels;