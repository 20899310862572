import React from "react";
import {Tabs, Tab} from "react-bootstrap";
import { TabbedInterfaceWrapper } from "./TabsInterface.style";
import RichTextRenderer from "../RichTextRenderer/RichTextRenderer";

const TabsInterface = ({data}) => {

    const tabItems = data.tabsItemsParent["en-US"];
    const tabMarkup = [];
    let iteration = 0;
    
    if (tabItems) {
        tabItems.forEach(tab => {
            const tabTitle = tab.fields.tabTitle["en-US"];
            const tabContent = tab.fields.copy["en-US"];
            tabMarkup.push(<Tab eventKey={iteration} title={tabTitle}><RichTextRenderer RichText={tabContent}/></Tab>)
            iteration++;
        });
    }

    return(
        <TabbedInterfaceWrapper>
            <Tabs
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                {tabMarkup}
            </Tabs>
      </TabbedInterfaceWrapper>
    );
};

export default TabsInterface;