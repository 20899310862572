import React from "react";
import RichTextRenderer from "../Global/RichTextRenderer/RichTextRenderer";

const Panel = ({data, classes}) => {

    const title = data.title;
    const copy = data.copy?.json;

    return(
        <div className={`flexipage-panel ${classes}`}>
            <div className="panel-title">
                <h3>{title}</h3>
            </div>
            <div className="panel-copy">
                <RichTextRenderer RichText={copy}/>
            </div>
        </div>
    )
}

export default Panel;