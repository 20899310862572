import React from "react";
import RichTextRenderer from "../Global/RichTextRenderer/RichTextRenderer";

const TestimonialPanel = ({data}) => {

    const name = data.testimonialName;
    const position = data.position;
    const copy = data.copy?.json;
    const image = data.image?.fluid.src;
    let altText = data.image?.description;
    let slideClass = "slide-content";

    if (!image) {
        slideClass += ' no-image';
    } else if (!altText) {
        altText = 'Testimonial Image';
    }

    return(
        <div className="slide">
            <div className={slideClass}>
                {image && 
                    <div className="image">
                        <img src={image} alt={altText} />
                    </div>
                }
                <div className="copy-wrapper">
                    <div className="copy-container">
                        <div className="name">{name}</div>
                        <div className="position">{position}</div>
                        <div className="copy">
                            <RichTextRenderer RichText={copy}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonialPanel;