import React from "react"
import { motion, useTransform, useMotionValue } from "framer-motion"
import { base_animation_trigger } from "./GlobalIntegrationSection"

const CurvedLineSvg = ({ className, scrollYProgress }) => {
  const path = useMotionValue(0);
  path.current = scrollYProgress;
  return (
    <svg
      className={`curved-line ${className}`}
      viewBox="0 0 475 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeLinecap="round"
    >
      <motion.path
        d="M475 1H73.8674C73.8674 1 -3.73804 6.45192 1.30808 82"
        stroke="#55B9A3"
        style={{
          pathLength: path,
        }}
      />
    </svg>
  )
}

export default CurvedLineSvg
