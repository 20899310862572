import React from "react"
import { useContext } from "react"
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import { MapContext } from "../TrulyGlobal/LogosMap"

const geoUrl =
  "/topojson.json"

const App = ({ setTooltipContent }) => {
  const [_, setState] = useContext(MapContext)
  const handleClick = (x) => {
    setState((prevState) => ({ ...prevState, location: x, selectContinent: true }))
  }
  return (
    <div>
      <ComposableMap data-tip="">
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                onMouseEnter={() => {
                  const { continent } = geo.properties
                  setTooltipContent(continent)
                }}
                onMouseLeave={() => {
                  setTooltipContent("")
                }}
                onClick={() => {
                  const { continent } = geo.properties
                  handleClick(continent)
                }}
                style={{
                  default: {
                    fill: "url(#pattern1)",
                    outline: "none",
                  },
                  hover: {
                    fill: "url(#pattern2)",
                    outline: "none",
                  },
                  active: {
                    fill: "url(#pattern2)",
                    outline: "none",
                  },
                  click: {
                    fill: "url(#pattern2)",
                    outline: "none",
                  },
                  pressed: {
                    fill: "url(#pattern2)",
                    outline: "none"
                  }
                }}
              />
            ))
          }
        </Geographies>
        <pattern
          id="pattern1"
          x="0"
          y="0"
          width="5.5"
          height="5.5"
          patternUnits="userSpaceOnUse"
        >
          <circle
            cx="1.7"
            cy="1.7"
            r="1.7"
            style={{ stroke: "none", fill: "#8FC5EA" }}
          />
        </pattern>
        <pattern
          id="pattern2"
          x="0"
          y="0"
          width="5.5"
          height="5.5"
          patternUnits="userSpaceOnUse"
        >
          <circle
            cx="1.7"
            cy="1.7"
            r="1.7"
            style={{ stroke: "none", fill: "#18A1FF" }}
          />
        </pattern>
      </ComposableMap>
    </div>
  )
}

export default App
