import React from "react";
import { TestimonialsRowWrapper } from "./TestimonialsRow.styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import TestimonialPanel from "./TestimonialPanel";
import { backgroundColourFormatter } from "../Global/utils/flexipageHelpers";

const TestimonialsRow = ({data}) => {

    const testimonials = data.testimonialItems;
    const bgColor = backgroundColourFormatter(data.backgroundColour) ? backgroundColourFormatter(data.backgroundColour) : '';
    const prevWidgetClass = data.addClassToPrevious ? data.addClassToPrevious  : '';
    let markup = [];
    
    if (testimonials) {
        testimonials.forEach(item => {
            markup.push(<TestimonialPanel data={item}/>);
        });    
    }
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    return(
        <TestimonialsRowWrapper id={data.rowId} className={`flexipage-row ${bgColor}${prevWidgetClass}`} data-animation-trigger="true">
            <div className="flexipage-container" data-animation="animate__fadeIn" data-animation-duration="1s" data-animation-delay="0.1s">
                <Slider {...settings}>
                    {markup}
                </Slider>
            </div>
        </TestimonialsRowWrapper>
    )
}

export default TestimonialsRow;