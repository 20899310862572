export function backgroundColourFormatter(color){

    if(!color){
        return;
    }

    switch (color) {
        case "Grey":
            return 'bg-light-grey';
        case "Dark Blue":
            return 'bg-dark-blue';
        case "Gradient Dark":
            return 'bg-gradient-dark';
        case "Gradient Mid":
            return 'bg-gradient-mid';
        case "Gradient Light":
            return 'bg-gradient-light';
        case "Gradient Neutral":
            return 'bg-gradient-neutral';
        case "Gradient Extra Light":
            return 'bg-gradient-extra-light';
        default:
            return 'bg-white';
    }
}

export function headingAltText (text){
    return text.replace(/\*(.*?)\*/g, '<span class="alt">$1</span>');
};