import styled from "styled-components";
import { contentStyles, FlexipageColors } from "../Global/FlexipageStyles";

export const TwoColumnsRowWrapper = styled.section`
    ${contentStyles}

    .columns-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 6em;

        &.align-center{
            .column{
                display: flex;
                align-items: center;
            }
        }

        @media screen and (max-width: ${FlexipageColors.breakpointTablet}) {
            grid-template-columns: 1fr;
            gap: 2em;
        }
    }
`