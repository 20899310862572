import { useTransform } from 'framer-motion'
import React from 'react'
import BigGreenCheck from './BigGreenCheck'
import { base_animation_trigger } from './GlobalIntegrationSection'

const BigGreenCheckAnimated = ({
  className,
  opacity,
  rotate
}) => {

  rotate = rotate = 180 - (rotate * 180);
  
  const style = {
    rotate,
    opacity,
    transition: {
      ease: "linear",
    },
  }
    return (
        <BigGreenCheck style={style} className={className}/>
    )
}

export default BigGreenCheckAnimated
