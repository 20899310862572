import styled from "styled-components";
import { contentStyles } from "../Global/FlexipageStyles";
import { FlexipageColors } from "../Global/FlexipageStyles";

export const PanelsRowWrapper = styled.section`
    ${contentStyles}

    .panel-row-intro{
        max-width: 800px;
        margin: 0em auto 4em auto;
    }

    &.three-column{
        .panels-container{
            grid-template-columns: 1fr 1fr 1fr;

            @media (max-width: 1100px){
                grid-template-columns: 1fr 1fr;
            }
        }
    }
    &.three-column, &.two-column, &{
        .panels-container{
            @media (max-width: 1100px){
                gap: 3em;
            }
            @media (max-width: ${FlexipageColors.breakpointMobile}){
                grid-template-columns: 1fr;
                gap: 2em;
            }
        }
    }

    .panels-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4em;

        @media (max-width: ${FlexipageColors.breakpointMobile}){
            grid-template-columns: 1fr;
        }

        .flexipage-panel{
            border-radius: 16px;
            text-align: left;

            .panel-title{
                margin-bottom: 20px;
                
                h3{
                    text-transform: uppercase;
                    font-family: 'Ambit';
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 30px;
                    text-align: left;
                    position: relative;
                }
            }

            .panel-copy{
                *:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }

    &.row-panels{
        .flexipage-panel{
            padding: 40px 40px;
            border-radius: 16px;
        }
    }
    &.bg-gradient-neutral.row-panels.panel-white{
        .flexipage-panel{
            background-color: var(--color--white);
        }
    }
    &.bg-gradient-neutral.row-panels.panel-white, &.bg-white.no-panels{
        .flexipage-panel{
            .panel-title{
                h3{
                    display: inline;
                    background: linear-gradient(to bottom, transparent 20px, rgba(76, 185, 239, 0.2) 50%);
                    background-size: 100% 30px;
                    background-position: 0 100%;
                    background-repeat: repeat;
                    /* background: linear-gradient(to bottom, transparent 16px, rgba(76, 185, 239, 0.2) 50%);
                    padding-bottom: 4px; */
                }
            }
        }
    }

    h2, h3, h4, h5, h6{
        color: var(--color--primary);
    }
`