import React from "react";
import { AltRowWrapper } from "./AltRow.styles";
import AltItem from "./AltItem";
import { backgroundColourFormatter } from "../Global/utils/flexipageHelpers";

const AltRow = ({data}) => {

    const altRowItems = data.altRowItems;
    const bgColor = backgroundColourFormatter(data.backgroundColour);
    const rowAlign = data.rowAlignment;
    const prevWidgetClass = data.addClassToPrevious ? data.addClassToPrevious  : '';

    let rowAlignment = '';
    if (rowAlign === 'Text Left') {
        rowAlignment = 'text-left';
    }
    else if (rowAlign === 'Image Left') {
        rowAlignment = 'image-left';
    }

    let markup = [];
    if (altRowItems) {
        altRowItems.forEach(rowContent => {
            markup.push(<AltItem data={rowContent}/>)
        });
    }

    return(
        <AltRowWrapper id={data.rowId} className={`flexipage-row ${bgColor}${prevWidgetClass}`} data-animation-trigger="true">
            <div className="alt-row-container">
                <div className={`alt-items-container ${rowAlignment}`} data-animation="animate__fadeIn" data-animation-duration="1s" data-animation-delay="0.1s">
                    {markup}
                </div>    
            </div>
        </AltRowWrapper>
    )
}

export default AltRow;