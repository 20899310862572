import React from "react";
import SVGArrow from "./Arrow";
import { HeroPanelWrapper, HeroPanelWrapperLink } from "./HeroPanel.styles";

const HeroPanel = ({ label, link, image }) => {
  const Wrapper = link ? HeroPanelWrapperLink : HeroPanelWrapper;

  if (image.file["en-US"]) {
    image.file.url = image.file["en-US"].url;
  }
  if (image.description["en-US"]) {
    image.description = image.description["en-US"];
  }
  if (image.title["en-US"]) {
    image.title = image.title["en-US"];
  }

  const panelImage = image.fluid?.src || image.file.url;
  const panelAlt = image.title || image.description;

  let panelImg;
  if (panelImage) {
    panelImg = <img src={panelImage} alt={panelAlt} />
  }

  const isExternalLink = (url) => {
    return url && (url.startsWith('http://') || url.startsWith('https://'));
  };

  const targetProps = isExternalLink(link)
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  return (
    <Wrapper className="panel" href={link ? link : undefined} {...targetProps}>
      {link && (
        <div className="arrow"> <SVGArrow/> </div>
      )}
      <div className="icon mb-3">
        {panelImg}
      </div>
      <div className="text-black poppins font-weight-bold">{label}</div>
    </Wrapper>
  );
};

export default HeroPanel;