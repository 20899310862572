import styled from "styled-components";
import { FlexipageColors } from "../Global/FlexipageStyles";

export const HeroPanelWrapper = styled.div`
    --color--link: var(--color--hero-cta--panel--text);
    
    flex-basis: 0;
    flex-grow: 1;
    position: relative;
    background: var(--color--white);
    border-radius: 16px;
    padding: 30px;
    color: var(--color--hero-cta--panel--text);
    transition: scale 0.3s;
    text-align: left;

    ::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--color--hero-cta--panel--background);
        top: 0;
        left: 0;
        display: block;
        border-radius: 16px;
        z-index: 0;
        opacity: 1;
        transition: opacity 0.3s;
    }

    div{
        position: relative;
        z-index: 1;
    }

    .arrow{
        display: none;
    }

    &[href]{
        opacity: 1;
        text-decoration: none;
        padding-right: 70px;

        .arrow{
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 30px;
        }

        &:hover{
            scale: 1.05;
            cursor: pointer;
            box-shadow: 0px 10px 30px 0px #00000026;
            opacity: 1;

            ::before{
                opacity: 0;
            }
        }    
    }

    .icon{
        text-align: left;
        img{
            max-width: 50px;
            max-height: 50px;
            width: auto;
            height: auto;
            margin-right: auto;
        }
    }
`
export const HeroPanelWrapperLink = HeroPanelWrapper.withComponent('a');
