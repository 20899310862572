import styled from "styled-components";

export const VideoEmbedWrapper = styled.div`
    width: 100%;
    margin: auto;
    padding-top: 56.25%;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    overflow: hidden;

     iframe, video{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
     }
`
