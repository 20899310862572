import React from "react";
import { HeroSectionWrapper } from "./HeroSection.styles";
import HeroPanel from "./HeroPanel";
import VideoEmbed from "../Global/VideoEmbed/VideoEmbed";
import RichTextRenderer from "../Global/RichTextRenderer/RichTextRenderer";
import { GenericContentStyles } from "../Global/FlexipageStyles";

const HeroSection = ({title, heroCopy, heroLeadImage, heroVideo, heroImage, heroIcon, heroPanel1Label, heroPanel1Link, heroPanel1Image, heroPanel2Label, heroPanel2Link, heroPanel2Image, heroPanel3Label, heroPanel3Link, heroPanel3Image}) => {

  function getDivStyle(){
    const returnContent = {
        backgroundImage: `url(${heroLeadImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%',
        width: '100%',
    }
    if (!heroLeadImage) {
        delete returnContent.backgroundImage;
    }

    return (returnContent);
  }

  const divStyle = getDivStyle();

  let heroImageAlt = 'lead image';
  let heroIconAlt = 'hero icon image';
  let leadSectionClass = "lead-section no-grid";
  heroImage = heroImage?.fluid?.src || heroImage?.file?.url;
  heroIcon = heroIcon?.fluid?.src || heroIcon?.file?.url;

  if (heroImage?.description) {
    heroImageAlt = heroImage.description;
  }
  if (heroIcon?.description) {
    heroIconAlt = heroIcon.description;
  }
  if (heroVideo) {
    leadSectionClass = "lead-section";
    heroImage = false;
  }
  if (heroImage) {
    leadSectionClass = "lead-section";
  }

  function getPanels() {

    const panels = [];
    if (heroPanel1Label) {
        panels.push(<HeroPanel label={heroPanel1Label} link={heroPanel1Link} image={heroPanel1Image}/>);
    }
    if (heroPanel2Label) {
        panels.push(<HeroPanel label={heroPanel2Label} link={heroPanel2Link} image={heroPanel2Image}/>);
    }
    if (heroPanel3Label) {
        panels.push(<HeroPanel label={heroPanel3Label} link={heroPanel3Link} image={heroPanel3Image}/>);
    }

    return panels;
  };

  const heroPanels = getPanels();

    return(
        <HeroSectionWrapper data-animation-trigger="true">
            <div className="hero-background" style={divStyle}></div>
            <GenericContentStyles>    
                <div className="hero-container">
                    <div className={leadSectionClass} data-animation="animate__fadeIn" data-animation-duration="1s" data-animation-delay="0.1s">
                        <div className="copy-region">
                            {heroIcon && 
                            <div className="hero-icon-image">
                                <img src={heroIcon} alt={heroIconAlt} />
                            </div>
                            }
                            <h1 className="title" dangerouslySetInnerHTML={{ __html: title }}></h1>
                            <RichTextRenderer RichText={heroCopy} />
                        </div>
                        {heroVideo && 
                        <div className="video-region">
                            <div className="video-wrapper">
                                <VideoEmbed url={heroVideo} />
                            </div>
                        </div>
                        }
                        {heroImage && 
                        <div className="hero-image">
                            <img src={heroImage} alt={heroImageAlt} />
                        </div>
                        }
                    </div>
                    <div className="hero-panels" data-animation="animate__fadeIn" data-animation-duration="1s" data-animation-delay="1s">
                        {heroPanels}
                    </div>
                </div>
            </GenericContentStyles>
        </HeroSectionWrapper>
    )
};

export default HeroSection;