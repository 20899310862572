import React, { useEffect, useRef, useMemo } from "react"
import Layout from "../components/Global/Layout/Layout";
import HeroSection from "../components/Flexipage/HeroSection/HeroSection";
import AccordionRow from "../components/Flexipage/AccordionRow/AccordionRow";
import AltRow from "../components/Flexipage/AltRow/AltRow";
import CallToActionRow from "../components/Flexipage/CallToActionRow/CallToActionRow";
import CopyRow from "../components/Flexipage/CopyRow/CopyRow";
import PanelsRow from "../components/Flexipage/PanelsRow/PanelsRow";
import TestimonialsRow from "../components/Flexipage/TestimonialsRow/TestimonialsRow";
import TwoColumnsRow from "../components/Flexipage/TwoColumnsRow/TwoColumnsRow";
import WidgetRow from "../components/Flexipage/WidgetRow/WidgetRow";
import { headingAltText } from "../components/Flexipage/Global/utils/flexipageHelpers";
import { FlexipageWrapper } from "../components/Flexipage/Global/FlexipageStyles";
import ResponsiveTables from "../components/Flexipage/Global/ResponsiveTables/ResponsiveTables";
import { animatePage } from "../components/Flexipage/Global/utils/animationScript";
import SEO from "../components/Global/SEO/seo";
import { backgroundColourFormatter } from "../components/Flexipage/Global/utils/flexipageHelpers";

const Flexipage = ({data}) => {

    function getRows(content){
        const rows = content.flexipageRows;
        let markup = [];
        let rowKeyCount = 0;
        let previousRowIndex = null; // Keep track of the previous row index

        rows.forEach((rowData, index) => {
            const rowType = rowData.__typename;
            rowKeyCount++;
            const rowKey = `row-${rowKeyCount}`;
            delete rowData.id;

            // If the current rowType is 'ContentfulWidgetRow', add a custom property to the previous row's data
            if (rowType === 'ContentfulWidgetRow' && previousRowIndex !== null) {
              let widgetClass = ' widget-row-next';
              switch (rowData.widgetSelector) {
                case "Payment Orchestration Platform Diagram":
                    widgetClass = ' widget-row-next-truly-global';
                    break;
                case "BNPL Logos Slider":
                    widgetClass = ' widget-row-next-bnpl-logos';
                    break;
                case "Logos Map":
                    widgetClass = ' widget-row-next-logos-map';
                    break;
                default:
                    break;
            }

              let prevBgColor;
              prevBgColor = backgroundColourFormatter(markup[previousRowIndex].props.data?.backgroundColour);
              rowData.backgroundColour = prevBgColor ? prevBgColor : false;

              markup[previousRowIndex] = React.cloneElement(markup[previousRowIndex], {
                  data: { ...markup[previousRowIndex].props.data, addClassToPrevious: widgetClass },
              });
            }

            let newRow;
            switch (rowType) {
                case 'ContentfulAccordionRow':
                    newRow = <AccordionRow data={rowData} key={rowKey} />;
                    break;
                case 'ContentfulAltRow':
                    newRow = <AltRow data={rowData} key={rowKey} />;
                    break;
                case 'ContentfulCallToActionRow':
                    newRow = <CallToActionRow data={rowData} key={rowKey} />;
                    break;
                case 'ContentfulCopyRow':
                    newRow = <CopyRow data={rowData} key={rowKey} />;
                    break;
                case 'ContentfulPanelsRow':
                    newRow = <PanelsRow data={rowData} key={rowKey} />;
                    break;
                case 'ContentfulTestimonialsRow':
                    newRow = <TestimonialsRow data={rowData} key={rowKey} />;
                    break;
                case 'ContentfulTwoColumnsRow':
                    newRow = <TwoColumnsRow data={rowData} key={rowKey} />;
                    break;
                case 'ContentfulWidgetRow':
                    newRow = <WidgetRow data={rowData} key={rowKey} />;
                    break;
            }
            markup.push(newRow);
            previousRowIndex = index; // Update the index of the previous row
        });
        return markup;
    }

    let post = data.contentfulFlexipage;
    const markup = useMemo(() => getRows(post), [post]);
    const title = headingAltText(post.title);
    const heroCopy = post.childContentfulFlexipageHeroCopyRichTextNode?.json;
    const heroLeadImage = post.heroBackgroundImage?.fluid.src;
    const heroImage = post.heroLeadImage;
    const heroIcon = post.heroIcon;
    const heroVideo = post.heroVideo?.videoUrl;
    const heroPanel1Label = post.heroPanel1Label;
    const heroPanel1Link = post.heroPanel1Link;
    const heroPanel1Image = post.heroPanel1Image;
    const heroPanel2Label = post.heroPanel2Label;
    const heroPanel2Link = post.heroPanel2Link;
    const heroPanel2Image = post.heroPanel2Image;
    const heroPanel3Label = post.heroPanel3Label;
    const heroPanel3Link = post.heroPanel3Link;
    const heroPanel3Image = post.heroPanel3Image;
    const metaTitle = post.metaTitle || title;
    const metaDescription = post.metaDescription?.metaDescription;

    const pageRef = useRef(null);

    useEffect(() => {
      let animationTargets = pageRef.current.querySelectorAll('[data-animation-trigger]');  
      animationTargets = animatePage(animationTargets);
      window.addEventListener('scroll', function(){
        if (animationTargets && animationTargets.length > 0) {
          animationTargets = animatePage(animationTargets);
        }
      })  
    });

    return (
      <Layout 
        headerTransparent
        whiteToggler
        logoDark={false}
        helpSectionColor={'white-bg'}
      >
        <SEO title={metaTitle} description={metaDescription} />
        <FlexipageWrapper ref={pageRef}>
          <HeroSection 
            title={title} 
            heroCopy={heroCopy} 
            heroLeadImage={heroLeadImage} 
            heroVideo={heroVideo} 
            heroImage={heroImage}
            heroIcon={heroIcon}
            heroPanel1Label={heroPanel1Label}
            heroPanel1Link={heroPanel1Link}
            heroPanel1Image={heroPanel1Image}
            heroPanel2Label={heroPanel2Label}
            heroPanel2Link={heroPanel2Link}
            heroPanel2Image={heroPanel2Image}
            heroPanel3Label={heroPanel3Label}
            heroPanel3Link={heroPanel3Link}
            heroPanel3Image={heroPanel3Image}
          />
          <ResponsiveTables className="flexipage-rows">
            {markup}
          </ResponsiveTables>
        </FlexipageWrapper>
      </Layout>
    )  
};

export default Flexipage

export const query = graphql`
  query getFlexiPagePosts($slug: String) {
  contentfulFlexipage(slug: {eq: $slug}) {
    id
    title
    slug
    childContentfulFlexipageHeroCopyRichTextNode {
      json
    }
    metaDescription {
      metaDescription
    }
    metaTitle
    heroBackgroundImage {
      fluid(quality: 80, maxWidth: 1200) {
        src
      }
      file {
        url
      }
    }
    heroLeadImage {
      fluid(quality: 80, maxWidth: 900) {
        src
      }
      file {
        url
      }
      description
    }
    heroIcon {
      fluid(quality: 80, maxWidth: 400) {
        src
      }
      file {
        url
      }
      description
    }
    heroVideo {
      videoUrl
    }
    heroPanel1Label
    heroPanel1Image {
      title
      description
      fluid(quality: 80, maxWidth: 100) {
        src
      }
      file {
        url
      }
    }
    heroPanel1Link
    heroPanel2Label
    heroPanel2Image {
      title
      description
      fluid(quality: 80, maxWidth: 100) {
        src
      }
      file {
        url
      }
    }
    heroPanel2Link
    heroPanel3Label
    heroPanel3Image {
      title
      description
      fluid(quality: 80, maxWidth: 100) {
        src
      }
      file {
        url
      }
    }
    heroPanel3Link
    flexipageRows {
      ... on ContentfulAccordionRow {
        backgroundColour
        rowId
        copy {
          json
        }
        accordionItems {
          title
          copy {
            json
          }
        }
      }
      ... on ContentfulAltRow {
        rowAlignment
        backgroundColour
        rowId
        altRowItems {
          image {
            description
            title
            fluid(quality: 80, maxWidth: 800) {
              src
            }
            file {
              url
            }
          }
          copy {
            json
          }
          video {
            videoUrl
          }
        }
      }
      ... on ContentfulCallToActionRow {
        label
        rowId
        linkText
        linkUrl
        tagLine
      }
      ... on ContentfulCopyRow {
        backgroundColour
        rowId
        textAlignment
        copy {
          json
        }
      }
      ... on ContentfulPanelsRow {
        rowStyle
        rowId
        columnCount
        copy {
          json
        }
        panelItems {
          title
          copy {
            json
          }
        }
      }
      ... on ContentfulTestimonialsRow {
        backgroundColour
        rowId
        testimonialItems {
          testimonialName
          name
          position
          image {
            fluid(maxWidth: 600, quality: 80) {
              src
            }
          }
          copy {
            json
          }
        }
      }
      ... on ContentfulTwoColumnsRow {
        rowSubtitle
        rowId
        backgroundColour
        textAlignment
        column1Copy {
          json
        }
        column2Copy {
          json
        }
      }
      ... on ContentfulWidgetRow {
        widgetSelector
        rowId
      }
    }
  }
}`