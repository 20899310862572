import React from "react";
import { TwoColumnsRowWrapper } from "./TwoColumnsRow.styles";
import RichTextRenderer from "../Global/RichTextRenderer/RichTextRenderer";
import { backgroundColourFormatter } from "../Global/utils/flexipageHelpers";
import SubTitle from "../Global/SubTitle/SubTitle";

const TwoColumnsRow = ({data}) => {

    const column1 = data.column1Copy.json;
    const column2 = data.column2Copy.json;
    const textAlignment = data.textAlignment.toLowerCase();
    const bgColor = backgroundColourFormatter(data.backgroundColour) ? backgroundColourFormatter(data.backgroundColour) : '';
    const subtitle = data.rowSubtitle;
    const prevWidgetClass = data.addClassToPrevious ? data.addClassToPrevious  : '';
    let columnWrapperClass = "columns-wrapper";

    if(textAlignment === 'center'){
        columnWrapperClass = "columns-wrapper align-center" ;
    }

    return(
        <TwoColumnsRowWrapper id={data.rowId} className={`flexipage-row ${bgColor}${prevWidgetClass}`} data-animation-trigger="true">
            <div className="flexipage-container" data-animation="animate__fadeIn" data-animation-duration="1s" data-animation-delay="0.1s">
                <SubTitle subtitle={subtitle} />
                <div className={columnWrapperClass}>
                    <div className="column">
                        <div className="column-container">
                            <RichTextRenderer RichText={column1}/>
                        </div>
                    </div>
                    <div className="column">
                        <div className="column-container">
                            <RichTextRenderer RichText={column2}/>
                        </div>
                    </div>
                </div>
            </div>
        </TwoColumnsRowWrapper>
    )
}

export default TwoColumnsRow;