import React, { useEffect, useRef } from "react";

const ResponsiveTables = ({children, className = ""}) => {

    const contentContainer = useRef(null);

    function getColspan(cell) {
        return parseInt(cell.getAttribute('colspan') ?? '1');
    }
  
    function getTableHeadings(table) {
        let headings = [];

        table
            .querySelectorAll('tr:first-child th, tr:first-child td')
            .forEach(cell => {
            const colspan = getColspan(cell);
            for(let i=0; i<colspan; i++){
                headings.push(cell.textContent.trim() ?? '');
            }
            });

        return headings;
    }

    function addTitleAttribute(tr, headings) {
        let offset = 0;

        tr
            .querySelectorAll('td, th')
            .forEach(cell => {
            const colspan = getColspan(cell);
            cell.setAttribute('data-title', headings[offset]);
            offset += colspan;
            });
    }

    function isTableBiggerThanParent(table) {
        const parentRawWidth = window.getComputedStyle(table.parentElement).width;
        if(!/px$/.test(parentRawWidth)) return false;
        const tableWidth = table.clientWidth;
        const parentWidth = Math.ceil(parseFloat(parentRawWidth));
        return tableWidth > parentWidth;
    }

    function addTableTitleAttributes(table) {
        const headings = getTableHeadings(table);
        table
            .querySelectorAll('tbody tr')
            .forEach(row => addTitleAttribute(row, headings));
    }

    function toggleResponsiveTableClass(tables) {
        tables.forEach(table => {
            table.classList.remove('responsive');
            if(isTableBiggerThanParent(table) || window.innerWidth <= 767) table.classList.add('responsive');
        });
    }

    function setupTables() {
        const tables = contentContainer.current.querySelectorAll("table");
        tables.forEach(table => {
            addTableTitleAttributes(table);
            toggleResponsiveTableClass([table]);
        });
    }

    function handleResize() {
        setupTables();
    }

    useEffect(() => {
        setupTables();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });
    
    return(
        <div ref={contentContainer} className={className}>{children}</div>
    );
}

export default ResponsiveTables;
