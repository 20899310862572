import React from "react";
import { ResponsiveImageWrapper } from "./ResponsiveImage.styles";

const ResponsiveImage = ({data}) => {

    const desktopImage = `${data.desktopImage["en-US"].fields.file["en-US"].url}?w=1170&q=80`;
    const mobileImage = `${data.mobileImage["en-US"].fields.file["en-US"].url}?w=600&q=80`;

    let desktopDescription = data.desktopImage["en-US"].fields.description["en-US"] || data.desktopImage["en-US"].fields.title["en-US"] || 'desktop responsive image';
    let mobileDescription = data.mobileImage["en-US"].fields.description["en-US"] || data.mobileImage["en-US"].fields.title["en-US"] || 'mobile responsive image';

    return(
        <ResponsiveImageWrapper>
            <img className='desktop' src={desktopImage} alt={desktopDescription} />
            <img className='mobile' src={mobileImage} alt={mobileDescription} />
        </ResponsiveImageWrapper>       
    )
}

export default ResponsiveImage;