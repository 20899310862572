import React, { useState } from "react"
import { StyledMapSection } from "./MapSection.styles"
import MapChart from "./CustomMap"
import ReactTooltip from "react-tooltip"

const MapSection = () => {
  const [content, setContent] = useState("")

  return (
    <StyledMapSection>
      <MapChart setTooltipContent={setContent} />
      <ReactTooltip>{content}</ReactTooltip>
    </StyledMapSection>
  )
}

export default MapSection
