import React from "react";

const ButtonLink = ({data}) => {

    const linkText = data.linkText["en-US"];
    const buttonStyle = (style = data.buttonStyle["en-US"]) => {
        if (style === 'Default' || !style){
            return `button`;
        }
        style = style.toLowerCase();
        return `button ${style}`;
    };

    const linkUrl = data.linkUrl["en-US"];
    const validateLink = (link) => {
        if (!link) {
          return false;
        }

        if (link.startsWith('http://') || link.startsWith('https://')) {
          return 'external';
        }
        
        if (link.startsWith('/')) {
          const slashes = link.match(/^\/+/);
          if (slashes && slashes[0].length > 1) {
            return false;
          }
          return 'internal';
        }
        return false;
    };
    const linkType = validateLink(linkUrl);
    
    if (!linkType) {
        return null;
    }

    return (
        <a 
            href={linkUrl} 
            className={buttonStyle()} 
            target={linkType === 'external' ? "_blank" : undefined}
            rel={linkType === 'external' ? "noopener noreferrer" : undefined}
        >
            {linkText}
        </a>
    );
}

export default ButtonLink;
