import React from "react";
import { AccordionRowWrapper } from "./AccordionRow.styles";
import AccordionItem from "./AccordionItem";
import RichTextRenderer from "../Global/RichTextRenderer/RichTextRenderer";
import { backgroundColourFormatter } from "../Global/utils/flexipageHelpers";

const AccordionRow = ({data}) => {

    const copy = data.copy?.json;
    const accordionsArray = data.accordionItems;
    const accordionsMarkup = [];
    const bgColor = backgroundColourFormatter(data.backgroundColour);
    const prevWidgetClass = data.addClassToPrevious ? data.addClassToPrevious  : '';

    if (accordionsArray) {
        accordionsArray.forEach(accordionItem => {
            accordionsMarkup.push(<AccordionItem data={accordionItem}/>)
        });
    }

    return(
        <AccordionRowWrapper className={`flexipage-row ${bgColor}${prevWidgetClass}`} data-animation-trigger="true">
            <div className="flexipage-container" data-animation="animate__fadeIn" data-animation-duration="1s" data-animation-delay="0.1s">
                <div className="accordion-row-copy">
                    <RichTextRenderer RichText={copy}/>
                </div>
                {accordionsMarkup}
            </div>
        </AccordionRowWrapper>
    )
}

export default AccordionRow;