import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const LogoImages = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "bnpl-connect/bnplLogos/01"}}) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 75, maxWidth: 150) {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
    }
  `)
  var logosArray = [];
  for (let i = 0; i < 4; i++) {
    for (let i = 0; i < data.allFile.edges.length; i++) {
        logosArray.push(<p><div className="payment-logo"><img src={data.allFile.edges[i].node.childImageSharp.fluid.src} alt={data.allFile.edges[i].node.name + " logo"} /></div></p>);
    }
  }
  return (

    <div class="slider-container" id="LogosSlider">
    <div class="scroll-disabler"></div>
      {logosArray}
    </div>
  )
}

export default LogoImages

