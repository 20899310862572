import styled, { css } from "styled-components";

export const TabbedInterfaceWrapper = styled.section`
  background-color: var(--color--tabbed--container--background);
  max-width: 1300px;
  padding: 20px;
  border-radius: 10px;
  margin: 3em 0;
  //box-shadow: 0px 10px 30px 0px #00000026;

  .tab-content{
    margin-top: 2em;
    margin-bottom: 3px;
    color: var(--color--tabbed--container-text);

    p{
        color: var(--color--tabbed--container-text);
    }

    *:last-child{
        margin-bottom: 0;
    }
  }

  a{
    color: var(--color--tabbed--container-text);
    text-decoration: underline!important;

    &:hover{
        text-decoration: none!important;
    }
  }

  a.nav-item, a.nav-link{
    color: var(--color--tabbed--container-text);
    text-decoration: none!important;
    padding: 10px 15px;
  }
`