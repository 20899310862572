import styled from "styled-components";

export const SubTitleWrapper = styled.div`
    color: var(--color--paragraph);
    margin-bottom: 2em;
    position: relative;
    padding-left: 50px;

    &::before{
        content: "";
        display: block;
        position: absolute;
        background-color: var(--color--subtitle--circle);
        width: 57px;
        height: 57px;
        border-radius: 50%;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        background-color: var(--color--subtitle--line);
        width: 30px;
        height: 1px;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
    }
`