import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const ComponentName = ({ logoImages }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "gateway/GlobalBrandz" } }
        sort: { fields: childImageSharp___fluid___originalName }
        ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 75, maxWidth: 120) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
            name
            publicURL
          }
        }
      }
    }
  `)
  var logosArray = [];
  var imagesNamesArray = [];
  for (let i = 0; i < data.allFile.edges.length; i++) {
    imagesNamesArray.push(data.allFile.edges[i].node.name)
    /* if (logoImages.includes(data.allFile.edges[i].node.name)) {
      //logosArray.push([i, data.allFile.edges[i].node.childImageSharp.fluid.src])
      //logosArray.push(<div className="logo"><img src={data.allFile.edges[i].node.childImageSharp.fluid.src} alt="" /></div>);
    } */
  }
  for (let i = 0; i < logoImages.length; i++) {
    const imageIndex = imagesNamesArray.indexOf(logoImages[i]);
    const imageURL = data?.allFile?.edges[imageIndex]?.node?.childImageSharp?.fluid?.src ? data?.allFile?.edges[imageIndex]?.node?.childImageSharp?.fluid?.src : data?.allFile?.edges[imageIndex]?.node?.publicURL;
    logosArray.push(<div className="logo"><img src={imageURL} alt={logoImages[i] + ' logo'} /></div>);
  }
  //console.log(logosArray);
  return (
  <div className="logos mt-3">
      {logosArray}
    </div>
  )
}

export default ComponentName

