import * as React from "react"
import { motion, useTransform } from "framer-motion"
import { StyledLineWrapper } from "./Divider.styles"
import { useEffect, useState } from "react"

const NewDivider = ({
  height,
  range,
  scrollYProgress,
  style,
  hideBall = false,
}) => {
  let bottomRange = range[0];
  let topRange = range[1];
  let percentage = ((scrollYProgress - bottomRange) / (topRange - bottomRange));
  let ballOpacity = percentage * 20;
  percentage = Math.max(0, Math.min(percentage, 1));
  ballOpacity = Math.max(0, Math.min(ballOpacity, 1));
  
  const scaleTop = (height - 11) * percentage;

  return (
    <StyledLineWrapper style={style}>
      <motion.div
        className="line"
        style={{
          height: `${height}px`,
          scaleY: percentage,
          transitionTimingFunction: "ease",
          transitionDuration: ".1s",
        }}
      />

      <motion.div
        className="circle secondary-blue-2-background"
        style={{
          top: scaleTop,
          opacity: ballOpacity,
          transitionDuration: ".1s",
          transitionProperty: "opacity",
          transitionTimingFunction: "ease",
        }}
      />
    </StyledLineWrapper>
  )
}

export default NewDivider
