import React from "react";
import { WidgetRowWrapper } from "./WidgetRow.styles";
import BnplLogos from "../../BnplConnect/BnplLogos/BnplLogos";
import GlobalIntegrationSection from "../../HomePage/GlobalIntegrationSection/AdaptiveGlobalIntegrationSection";
import LogosMap from "../../paymentOrchestrationLayer/TrulyGlobal/LogosMap";
import { backgroundColourFormatter } from "../Global/utils/flexipageHelpers";

const WidgetRow = ({data}) => {

    const widgetSelector = data.widgetSelector;
    const bgColor = backgroundColourFormatter(data.backgroundColour);
    
    let markup;
    let widgetClass;
    
    switch (widgetSelector) {
        case "Payment Orchestration Platform Diagram":
            markup = <GlobalIntegrationSection/>
            widgetClass = `widget-truly-global prev-${bgColor}`;
            break;
        case "BNPL Logos Slider":
            markup = <BnplLogos/>
            widgetClass = 'widget-bnpl-logos';
            break;
        case "Logos Map":
            markup = <LogosMap/>
            widgetClass = `widget-logos-map prev-${bgColor}`;
            break;
        default:
            break;
    }

    return(
    <WidgetRowWrapper className={`widget-row flexipage-row ${widgetClass}`} id={data.rowId} data-animation-trigger="true" data-animation="animate__fadeIn" data-animation-duration="1s" data-animation-delay="0.1s">
        {markup}
    </WidgetRowWrapper>
        
    )
}

export default WidgetRow;