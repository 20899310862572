import React from "react";
import RichTextRenderer from "../Global/RichTextRenderer/RichTextRenderer";

const AccordionItem = ({data}) => {

    const title = data.title
    const copy = data.copy?.json

    return(

        <details>
            <summary>
                {title}
            </summary>
            <div className="accordion-item-copy">
                <RichTextRenderer RichText={copy}/> 
            </div>
        </details>
    );
};

export default AccordionItem;