import styled from "styled-components"
import { fonts, colors } from "../../../utils/styles"

export const TrulyGlobalWrapper = styled.section`
  
  h4 {
    font-family: ${fonts.nunito};
  }
  .truly-global{
    color: #428AC9;

    @media (max-width: 992px) {
        font-size: 1.75rem;
    }
  }
  .pill {
    button {
      &.selected {
        background-color: ${colors.secondaryBlue3} !important;
      }
      &.unselected {
        background-color: #d2d2d2 !important;
      }
      &:first-child {
        border-top-left-radius: 30px !important;
        border-bottom-left-radius: 30px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
      &:last-child {
        border-top-right-radius: 30px !important;
        border-bottom-right-radius: 30px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
      &:active {
        outline: none;
        border: none;
      }
      &:focus {
        outline: 0 !important;
        box-shadow: none !important;
      }
    }
  }
  .tr-img {
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
  }

#local-processors {
  .location-header{
    text-align: center;
  }
  .logos{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;
    position: relative;
    align-items: center;
    @media screen and (max-width: 992px){
      grid-template-columns: repeat(5, 1fr);
    }
    @media screen and (max-width: 780px){
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: 600px){
      grid-template-columns: repeat(3, 1fr);
    }
    .logo{
      padding: 20px 15%;
      img{
          position: relative;
          max-width: 100%;
          max-height: 50px;
      }
    }
  }

  .button-select{
    background-color: #698795 !important;
    font-size: 1.25rem;
    @media screen and (max-width: 991px){
      font-size: 1rem;
    }
    &.selected{
      background-color: #134056 !important;
    }
  }

}

.connecting-leading-acquirers{
    max-width: 800px;
  }

`
