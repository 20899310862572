import styled from "styled-components";
import { FlexipageColors } from "../FlexipageStyles";

export const ResponsiveImageWrapper = styled.div`
    margin: 2em 0;
    
    .desktop{
        display: block;
        max-width: 100%;
    }
    .mobile{
        display: none;
        max-width: 400px;
        max-width: 100%;
        margin: auto;
    }

    @media (max-width: ${FlexipageColors.breakpointMobile}) {
        .desktop{
            display: none;
        }
        .mobile{
            display: block;
        }
    }
`