import styled from "styled-components";
import { contentStyles } from "../Global/FlexipageStyles";
import { FlexipageColors } from "../Global/FlexipageStyles";

export const AltRowWrapper = styled.section`
    ${contentStyles}

    &.bg-white{
        --color--headings: var(--color--primary);    
        --color--headings--alt: var(--color--secondary);
    }

    .alt-row-container{
        position: relative;
    }
    .alt-items-container{
        .alt-item{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 3rem;
            margin-bottom: 3em;

            &:nth-child(even){
                .image, .alt-video-container{
                    grid-column: 1;
                    
                    .alt-video-wrapper{
                        margin-left: auto;
                        margin-right: unset;

                        @media (max-width: ${FlexipageColors.breakpointTablet}) {
                            margin-left: unset;
                            margin-right: auto;
                        }
                    }
                }
                .copy-container{
                    grid-column: 2;

                    .copy{
                        margin-right: auto;
                        margin-left: unset;
                    }
                }
            }

            &:last-child{
                margin-bottom: 0;
            }

            .image, .alt-video-container{
                max-width: 100%;
                grid-column: 2;
                grid-row: 1;
                display: flex;
                align-items: center;

                img, .alt-video-wrapper{
                    width: 100%;
                    max-width: 750px;
                    max-height: 750px;
                    margin: unset;
                }
                
                img{
                    object-fit: contain;
                    width: 100%;
                    height: auto;
                }
            }

            .copy-container{
                display: flex;
                align-items: center;
                grid-column: 1;
                grid-row: 1;

                .copy{
                    max-width: 561px;
                    margin-left: auto;
                    margin-right: unset;

                    *:last-child{
                        margin-bottom: 0;
                    }
                }
            }

        }

        &.text-left{
            .alt-item{
                &, &:nth-child(even){
                    .image, .alt-video-container{
                        grid-column: 2;
                        justify-content: start;

                        img, .alt-video-wrapper{
                            margin-right: auto;
                            margin-left: unset;
                        }
                    }
                    .copy-container{
                        grid-column: 1;

                        .copy{
                            margin-left: auto;
                            margin-right: unset;
                        }
                    }
                }
            }
        }
        &.image-left{
            .alt-item{
                &, &:nth-child(even){
                    .image, .alt-video-container{
                        grid-column: 1;
                        justify-content: end;

                        img, .alt-video-wrapper{
                            margin-left: auto;
                            margin-right: unset;

                            @media (max-width: ${FlexipageColors.breakpointTablet}) {
                                margin-left: unset;
                                margin-right: auto;
                            }
                        }
                        
                    }
                    .copy-container{
                        grid-column: 2;

                        .copy{
                            margin-left: unset;
                            margin-right: auto;
                        }
                    }
                }
            }
        }
        &, &.text-left, &.image-left{
            .alt-item{
                @media (max-width: ${FlexipageColors.breakpointTablet}) {
                grid-template-columns: 1fr;
                gap: 2em;

                &, &:nth-child(even){
                    .copy-container{
                        grid-row: 1;
                        grid-column: 1;
                        display: block;

                        .copy{
                            max-width: unset;
                        }
                    }
                    .image, .alt-video-container{
                        grid-row: 2;
                        grid-column: 1;
                    }
                }
                
            }
            }
        }
    }

`