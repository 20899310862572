import React, {createContext, useState} from "react";
import TrulyGlobal from "./TrulyGlobal";

export const MapContext = createContext()

const MapContextProvider = ({ children }) => {
  const [state, setState] = useState({ location: "Europe", mode: "ACQ" })
  return (
    <MapContext.Provider value={[state, setState]}>
      {children}
    </MapContext.Provider>
  )
}

const LogosMap = () => {
    return(
        <MapContextProvider>
          <TrulyGlobal />
        </MapContextProvider>
    );
}

export default LogosMap;