import React from "react";
import { CallToActionRowWrapper } from "./CallToActionRow.styles";

const CallToActionRow = ({data}) => {

    const label = data.label;
    const tagLine = data.tagLine;
    const linkText = data.linkText;
    const linkUrl = data.linkUrl;

    return(
        <CallToActionRowWrapper id={data.rowId} className="flexipage-row bg-gradient-neutral" data-animation-trigger="true">
            <div className="flexipage-container" data-animation="animate__fadeIn" data-animation-duration="1s" data-animation-delay="0.1s">
                <h2>{label}:</h2>
                <div className="cta-content">
                    <div className="cta-tagline">
                        {tagLine}
                    </div>
                    <a href={linkUrl} className="button">{linkText}</a>    
                </div>
                
            </div>
        </CallToActionRowWrapper>
    )
}

export default CallToActionRow;