import React from "react";
import { SubTitleWrapper } from "./SubTitle.styles";

const SubTitle = ({subtitle}) => {

    return(
        <SubTitleWrapper className="subtitle">
            {subtitle}
        </SubTitleWrapper>
    )
};

export default SubTitle;