import styled from "styled-components"

export const BnplLogosWrapper = styled.section`
    .logos-container-mobile{
        margin-right: auto;
        margin-left: auto;
        @media screen and (max-width: 767px){
            max-width: 100%;
            .StyledLogosSlider{
                &:nth-of-type(even){
                    .slider-container{
                        padding-left: 5%;
                    }
                }
            }
        }
    }

    .color-verdigris{
        color: #56B8A5;
    }

  .StyledLogosSlider{
      &.slider-2{
        margin-left: -50px;
      }
    .slider-container {
        overflow-x: scroll;
        white-space: nowrap;
        display: flex;
        -ms-overflow-style: none;
        scrollbar-width: none;
        align-items: center;
        height: 110px;
        p{
            padding-left: 25px;
            margin: 0;
            .payment-logo{
                background-color: #FFF;
                border-radius: 15px;
                height: 90px;
                box-shadow: #04040425 0 0 6px 0;
                width: 180px;
                text-align: center;
                @media screen and (max-width: 767px){
                    border-radius: 10px;
                }
                img{
                    max-width: 130px;
                    max-height: 40px;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

        }
        .scroll-disabler {
            width: 100vw;
            height: 34.4px;
            position: absolute;
            background-color: rgba(0,0,0 , 0.0001);
            z-index: 1;
            height: 110px;
        }
        ::-webkit-scrollbar {
            display: none;
        }
        @media screen and (max-width:767px){
            height: 80px;
            p{
                padding-left: 15px;
                .payment-logo{
                    height: 70px;
                    width: 140px;
                    img{
                        max-width: 100px;
                        max-height: 30px;
                    }
                }
            }
            .scroll-disabler {
                height: 80px;
            }
        }
    }
}
`