import React from "react";
import { CopyRowWrapper } from "./CopyRow.styles";
import { Container } from "react-bootstrap";
import RichTextRenderer from "../Global/RichTextRenderer/RichTextRenderer";
import { backgroundColourFormatter } from "../Global/utils/flexipageHelpers";

const CopyRow = ({data}) => {

    let textAlignment = data.textAlignment;
    const prevWidgetClass = data.addClassToPrevious ? data.addClassToPrevious  : '';

    if (textAlignment === 'Center') {
        textAlignment = ' text-center';
    }

    const bgColor = backgroundColourFormatter(data.backgroundColour);
    
    return(
        <CopyRowWrapper id={data.rowId} className={`flexipage-row ${bgColor}${textAlignment}${prevWidgetClass}`} data-animation-trigger="true">
            <div className="flexipage-container" data-animation="animate__fadeIn" data-animation-duration="1s" data-animation-delay="0.1s">
                <RichTextRenderer RichText={data.copy?.json} />
            </div>
        </CopyRowWrapper>
    )
}

export default CopyRow;